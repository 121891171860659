import React from 'react';
import { Link } from 'gatsby'
import FullIcon from '../../assets/images/Outspoke_connected_horizontal.png'
import MobileTop from '../../assets/images/connected_title.png'
import Mobile1 from '../../assets/images/connected_organize.png'
import Mobile2 from '../../assets/images/connected_mobilize.png'
import Mobile3 from '../../assets/images/connected_analyze.png'
import Mobile4 from '../../assets/images/connected_share.png'
import {Grid, Hidden} from '@material-ui/core';
import StarIcon from '../../assets/images/star-icon.png'

const FourSpokes = () => {
    return (
        <section id="get_started" className="how-its-work-area pb-100">

          <Grid container direction='column' alignItems='center' style={{paddingLeft: '15%', paddingRight: '15%'}}>
            <span className="sub-title" style={{marginBottom: 24}}>
                <img src={StarIcon} alt="image" />
                The Four Spokes
            </span>

            <h2 style={{fontSize: 30, marginBottom: 30, textAlign: 'center'}}>Your Supply Chain - Connected</h2>

            <Hidden smDown>
              <img src={FullIcon} className="four-spokes-text"/>
              <Grid container direction='row'>
                <Grid item xs={3} style={{paddingRight: 15}}>
                  <h3 className="four-spoke-title-all">ORGANIZE</h3>
                  {/*<p className="four-spokes-sub">This is a full sentence. This is a longer full sentence. This keeps going on and on..</p>*/}
                  <ul>
                    <li className="brighter-p four-spoke-bp">Standardize and Protect Data</li>
                    <li className="brighter-p four-spoke-bp">CRM with Integrated Insights</li>
                    <li className="brighter-p four-spoke-bp">Reconciliation Interface</li>
                  </ul>
                </Grid>
                <Grid item xs={3} style={{paddingRight: 5}}>
                  <h3 className="four-spoke-title-all">ANALYZE</h3>
                  <ul>
                    <li className="brighter-p four-spoke-bp">Predictive, Revenue Generating</li>
                    <li className="brighter-p four-spoke-bp">Charts Powered by MongoDB</li>
                    <li className="brighter-p four-spoke-bp">Audits and Insights Dashboard, Email Alerts </li>
                  </ul>
                </Grid>
                <Grid item xs={3} style={{paddingLeft: 25}}>
                  <h3 className="four-spoke-title-all">SHARE</h3>
                  <ul>
                    <li className="brighter-p four-spoke-bp">Bi-directional, Permission-driven, Secure</li>
                    <li className="brighter-p four-spoke-bp">Add team members, assign accounts</li>
                    <li className="brighter-p four-spoke-bp">Your Data is never sold to your competitors</li>
                  </ul>
                </Grid>
                <Grid item xs={3} style={{paddingLeft: 45}}>
                  <h3 className="four-spoke-title-all">MOBILIZE</h3>
                  <ul>
                    <li className="brighter-p four-spoke-bp">Make and Receive Inventory Allocations</li>
                    <li className="brighter-p four-spoke-bp">Sell Pre-Production Lots</li>
                    <li className="brighter-p four-spoke-bp">Secure ACH Payment Gateway</li>
                  </ul>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <img src={MobileTop} style={{width: '80%', marginTop: 20, marginBottom: 20}} />
              <img src={Mobile1} style={{width: '80%', marginTop: 20, marginBottom: 20}} />
                <h3 className="four-spoke-title-all">ORGANIZE</h3>
                <ul>
                  <li className="brighter-p four-spoke-bp">Standardize and Protect Data</li>
                  <li className="brighter-p">CRM with Integrated Insights</li>
                  <li className="brighter-p">Reconciliation Interface</li>
                </ul>
              <img src={Mobile2} style={{width: '80%', marginTop: 20, marginBottom: 20}} />
                <h3 className="four-spoke-title-all">ANALYZE</h3>
                <ul>
                  <li className="brighter-p">Predictive, Revenue Generating</li>
                  <li className="brighter-p">Charts Powered by MongoDB</li>
                  <li className="brighter-p">Audits and Insights Dashboard, Email Alerts </li>
                </ul>
              <img src={Mobile3} style={{width: '80%', marginTop: 20, marginBottom: 20}} />
                <h3 className="four-spoke-title-all">SHARE</h3>
                <ul>
                  <li className="brighter-p">Bi-directional, Permission-driven, Secure</li>
                  <li className="brighter-p">Add team members, assign accounts</li>
                  <li className="brighter-p">Your Data is never sold to your competitors</li>
                </ul>
              <img src={Mobile4} style={{width: '80%', marginTop: 20, marginBottom: 20}}/>
                <h3 className="four-spoke-title-all">MOBILIZE</h3>
                <ul>
                  <li className="brighter-p">Make and Receive Inventory Allocations</li>
                  <li className="brighter-p">Sell Pre-Production Lots</li>
                  <li className="brighter-p">Secure ACH Payment Gateway</li>
                </ul>
            </Hidden>

          </Grid>

        </section>
    )
}

export default FourSpokes;
