import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import icon1 from '../../assets/images/VisibilityDark.png'
import icon2 from '../../assets/images/AnalyticsDark.png'
import icon3 from '../../assets/images/InsightsDark.png'
import icon4 from '../../assets/images/ERPCRMDark.png'
import icon5 from '../../assets/images/AllocationsDark.png'
import icon6 from '../../assets/images/QualityControlDark.png'
import shape2 from '../../assets/images/shape/circle-shape2.png'

const OurServices = () => {
    return (
        <section className="services-area ptb-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title" style={{maxWidth: 1000}}>
                    <span className="sub-title">
                        <img src={starIcon} alt="banner" />
                        A SOLUTION FOR ALL
                    </span>
                    <h2>An Integrated, Hub-and-Spoke Platform to Drive Your Production and Sales Cycles Further</h2>
                    {/*<p className="brighter-p" style={{maxWidth: 800}}>Harvest insights from new and historical transaction and inventory data, empower your key buyers and suppliers as well as your employees and investors.</p>*/}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon1} alt="banner" className="serviceIcon" />
                            </div>
                            <h3 className="services-title">
                              Real-Time Visibility
                            </h3>
                            <p>Follow your products across the supply chain, gaining insight into what sells and sits</p>

                            {/*<Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="banner" className="serviceIcon" />
                            </div>
                            <h3 className="services-title">
                              Practical Analytics
                            </h3>
                            <p>Algorithms identify trends and deliver them to your inbox while traditional visualizations, tables and charts give you different windows into your activity</p>

                            {/*<Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="banner" className="serviceIcon" />
                            </div>
                            <h3 className="services-title">
                              Insights
                            </h3>
                            <p>Data-supported suggestions on product freshness, inventory imbalances, and resupply levels</p>

                            {/*<Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon4} alt="banner" className="serviceIcon" />
                            </div>
                            <h3 className="services-title">
                              ERP Meets CRM
                            </h3>
                            <p>View your inventory in the context of your relationships and never miss out on future sales or limited supply</p>

                            {/*<Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon5} alt="banner" className="serviceIcon" />
                            </div>
                            <h3 className="services-title">
                              Allocations
                            </h3>
                            <p>Assign your inventory to relationships or objectives with custom quantities, prices, and expiration dates</p>

                            {/*<Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon6} alt="banner" className="serviceIcon" />
                            </div>
                            <h3 className="services-title">
                              Quality Control
                            </h3>
                            <p>Maintain a scope of awareness that keeps the right products in the right places at the right time</p>

                            {/*<Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>*/}
                        </div>
                    </div>

                    {/*<div className="col-lg-12 col-md-12">
                        <div className="load-more-btn text-center">
                            <Link to="/services" className="default-btn">
                                <i className="flaticon-refresh"></i> Load More <span></span>
                            </Link>
                        </div>
                    </div>*/}
                </div>
            </div>

            <div className="circle-shape2">
                <img src={shape2} alt="banner" />
            </div>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </section>
    )
}

export default OurServices
