import React from 'react'
import {Link} from 'gatsby'
import ModalVideo from 'react-modal-video'
import learning from '../../assets/images/explore-learning.jpg'
import StarIcon from '../../assets/images/star-icon.png'

const MapSection = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId='bk7McNUjWgw'
                onClose={() => setIsOpen(!isOpen)}
            />

            <div className="explore-learning-area pt-70 ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                                <span className="sub-title">
                                  <img src={StarIcon} alt="image" style={{marginRight: 5}} />
                                  FIRST FOCUS</span>
                                <h2>Domestic Cannabis Markets</h2>
                                <p className="brighter-p">Outspoke has brought data standardization and integrated analytics to the cannabis industry, mobilizing required Seed-to-Sale data, empowering licensed firms to thrive in the transitioning markets while preparing for federal legalization.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                            <img
                              src='https://s3.us-west-1.amazonaws.com/outspoke-images/os_map2.png'
                              style={{width: '100%'}}
                              />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MapSection
