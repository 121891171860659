import React from 'react';
import {Link} from 'gatsby'

import MapShape2 from '../../assets/images/shape/map-shape2.png'
import Partner1 from '../../assets/images/partner/partner1.png'
import Partner2 from '../../assets/images/partner/partner2.png'
import Partner3 from '../../assets/images/partner/partner3.png'
import Partner4 from '../../assets/images/partner/partner4.png'
import Partner5 from '../../assets/images/partner/partner5.png'
import Partner6 from '../../assets/images/partner/partner6.png'
import SupplyChain1 from '../../assets/images/workSmarter.png'
import StarIcon from '../../assets/images/star-icon.png'

const WhyChooseUs = () => {
    return (
        <section className="why-choose-us-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                      {/*<span className="sub-title">
                          <img src={StarIcon} alt="image" style={{marginRight: 5}} />
                          Real Talk
                      </span>*/}
                        <div className="why-choose-us-content" style={{borderRadius: 6}}>
                            <h2>Working Hard, Not Smart</h2>
                            <p>For centuries, business have struggled to effectively share information, resulting in lost revenue, supply chain disruptions and market failures.</p>
                            <p>While supply chain technology has been around for 7 decades, most solutions have empowered businesses to work harder, not smarter.</p>

                            {/*<Link to="/about-us" className="default-btn">
                                <i className="flaticon-right"></i>
                                More About Us <span></span>
                            </Link>*/}

                            <div className="map-shape2">
                                <img src={MapShape2} alt="MapShape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-brand-partners-list"
                          style={{
                            backgroundImage: `url("https://s3.us-west-1.amazonaws.com/outspoke-images/workSmarter.png")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: 560,
                            borderRadius: 6,
                          }}
                        >
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs;
