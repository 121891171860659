import React from 'react'
import icon1 from '../../assets/images/DaysSinceLaunchLight.png'
import icon2 from '../../assets/images/StateMarketsLight.png'
import icon3 from '../../assets/images/WholesaleLight.png'
import icon4 from '../../assets/images/RetailLight.png'
import formatNumber from '../../utils/formatNumber'

const Funfacts = ({data}) => {
  const startDate = new Date("3/15/2021");
  const endDate = new Date();
  let daysDiff = ((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)).toFixed();

  const wholesale = data.allMongodbBeMacrostats.nodes[data.allMongodbBeMacrostats.nodes.length-1] ? formatNumber(data.allMongodbBeMacrostats.nodes[data.allMongodbBeMacrostats.nodes.length-1].totalWholesale) : 0;
  const retail = data.allMongodbBeMacrostats.nodes[data.allMongodbBeMacrostats.nodes.length-1] ? formatNumber(data.allMongodbBeMacrostats.nodes[data.allMongodbBeMacrostats.nodes.length-1].totalRetail) : 0;

    return (
        <div className="funfacts-area ptb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon1} alt="features" className='ffIcon' />
                            </div>
                            <h3>{daysDiff} Days</h3>
                            <p className="brighter-p">Since launch</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon2} alt="features" className='ffIcon' />
                            </div>
                            <h3>7 States</h3>
                            <p className="brighter-p">Markets Covered</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon3} alt="features" className='ffIcon' />
                            </div>
                            <h3>{'$'+wholesale}</h3>
                            <p className="brighter-p">Wholesale</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6 col-md-3">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon4} alt="features" className='ffIcon' />
                            </div>
                            <h3>{'$'+retail}</h3>
                            <p className="brighter-p">Retail</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-6 col-md-6">

                    </div>

                    <div className="col-lg-6 col-sm-6 col-12 col-md-6">
                      <div style={{
                        borderLeft: '1px solid #fff',
                        borderBottom: '1px solid #fff',
                        borderRight: '1px solid #fff',
                        padding: 10,
                        textAlign: 'center',
                        width: '90%',
                        position: 'relative',
                        bottom: 30,
                        letterSpacing: 2,
                        borderBottomLeftRadius: 3,
                        borderBottomRightRadius: 3,
                      }}>Transactions Organized and Analyzed</div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Funfacts
