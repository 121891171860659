import React from 'react';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import MainBanner from '../components/MachineLearningAISolutions/MainBanner';
import Solutions from '../components/MachineLearningAISolutions/Solutions';
import AboutUs from '../components/MachineLearningAISolutions/AboutUs';
import Services from '../components/MachineLearningAISolutions/Services';
import HowItWork from '../components/MachineLearningAISolutions/HowItWork';
import RecentProjects from '../components/MachineLearningAISolutions/RecentProjects';
import TeamMember from '../components/MachineLearningAISolutions/TeamMember';
// import Testimonials from '../components/MachineLearningAISolutions/Testimonials';
import Partner from '../components/MachineLearningAISolutions/Partner';
import OurBlog from '../components/MachineLearningAISolutions/OurBlog';
import ProjectStartArea from '../components/MachineLearningAISolutions/ProjectStartArea';
import WhyChooseUs from '../components/MachineLearningAISolutions/WhyChooseUs'
import OurServices from "../components/MachineLearningAISolutions/OurServices"
import Funfacts from "../components/MachineLearningAISolutions/Funfacts"
import MapSection from "../components/MachineLearningAISolutions/MapSection"
import FourSpokeDiagram from "../components/MachineLearningAISolutions/FourSpokeDiagram"
import FourSpokes from "../components/MachineLearningAISolutions/FourSpokes"
import SEOpage from './SEOpage';
import { graphql } from 'gatsby'

const Home = ({data}) => {
  const keywords = ['supply chain', 'cannabis', 'analytics'];
  const description = "Home page for Outspoke.io";

    return (
        <Layout>
          <SEOpage title="Home" keywords={keywords} description={description} />
            <Navbar />
            <MainBanner />
            <Solutions />
            <MapSection />
            <Funfacts data={data}/>
            <WhyChooseUs />
            <HowItWork />
            <FourSpokes />
            <OurServices />
            <Partner />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export const query = graphql`
  query IndexQuery {
    allMongodbBeMacrostats {
      nodes {
        totalWholesale
        totalRetail
      }
      edges {
        node {
          totalRetail
          totalWholesale
          date
        }
      }
    }
  }
`

export default Home;
